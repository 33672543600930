<template>
  <brand-header :image="urlImage" :altImage="altImagen" />
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <!-- <menu-component/> -->
        <!-- <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
          <span class="material-symbols-rounded">menu</span>
        </button> -->
        <a href="/" class="header-content__container-logo">
          <img class="header-content__img" src="../assets/images/logo.png" alt="wagerStore" />
        </a>
      </div>
      <div class="header-content__cta">
        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <!-- Desktop form -->
        <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
          <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
          <input class="login__form--inputs" type="password" name="password" placeholder="PASSWORD" id="password">

          <div class="header-buttons">
            <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
            <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
          </div>
        </form>
      </div>
    </div>
    <div class="max-container">
      <!-- Mobile form -->
      <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
        <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
        <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
        <input class="login__form--inputs" type="password" placeholder="PASSWORD" name="password" id="password">

        <div class="header-buttons">
          <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
          <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
        </div>
      </form>
    </div>
    <div class="header-content__info">
      <ul class="header-content__info--list">
        <li class="header-content__info--list-item" @click="isActive = !isActive" v-for="(route, index) in routes"
          :key="index">
          <router-link :to="route.route">{{ route.item }}</router-link>
        </li>
      </ul>
    </div>

    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from '@/components/modal.vue'
import BrandHeader from '@/components/brand-header.vue';
// import MenuComponent from '@/components/menu.vue'

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
    BrandHeader
    // MenuComponent
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    }
  },
  setup() {
    const DGS_SITEID = 84;
    const backendUrl = "playhere247.com"; 
    return {
      DGS_SITEID,
      backendUrl,
      urlImage: require('../assets/images/logo.png'),
      altImage: 'wagerStore',

      routes: [
        {
          item: 'sports',
          route: 'sportsbook'
        },
        {
          item: 'live betting',
          route: 'live-betting'
        },
        {
          item: 'casino',
          route: 'casino'
        },
        {
          item: 'racebook',
          route: 'racebook'
        }
        // {
        //   item       :  'props builder',
        //   route      :  'props-builder'
        // }
      ]
    }
  },
};
</script>
