<template>
  <section class="agent-buttons">
    <img src="../assets/images/logo.png" width="0" alt="wagerStore">
    <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agent classic</a>
    <a class="primary-button primary-button__new" :href="agentNew" target="_blank">agent new</a>
  </section>
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const agentClassic = "http://agents.playhere247.com "; 
      const agentNew = "https://adm.playhere247.com/home/landing"; 
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
